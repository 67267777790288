import React from "react";

// Primereact components
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";

import { MESSAGE_KEYS } from "assets/staticData/enums";
// Localization
import { useIntl } from "react-intl";

const AdditionalTimeRow = ({
  garageStart,
  garageEnd,
  pause,
  showButton,
  changeValue,
  index,
  addRow,
  deleteRow,
}) => {
  const {
    DRIVES_HEADER_GARAGE_START,
    DRIVES_HEADER_GARAGE_END,
    DRIVES_HEADER_PAUSE,
  } = MESSAGE_KEYS;
  const intl = useIntl();
  return (
    <>
      <div className="col-4">
        <div style={{ width: "100px" }} className="label-input">
          {intl.formatMessage({
            id: DRIVES_HEADER_GARAGE_START,
          })}
          :
        </div>
        <div>
          <InputMask
            mask="99:99"
            slotChar="hh:mm"
            value={garageStart}
            onChange={(event) => {
              changeValue(index, "starttime", event.target.value);
            }}
            style={{
              width: "100%",
              outline: garageStart ? "none" : "3px solid red",
            }}
          />
        </div>
      </div>
      <div className="col-4">
        <div style={{ width: "100px" }} className="label-input">
          {intl.formatMessage({
            id: DRIVES_HEADER_GARAGE_END,
          })}
          :
        </div>
        <div>
          <InputMask
            mask="99:99"
            slotChar="hh:mm"
            value={garageEnd}
            onChange={(event) => {
              changeValue(index, "endtime", event.target.value);
            }}
            style={{
              width: "100%",
              outline: garageEnd ? "none" : "3px solid red",
            }}
          />
        </div>
      </div>
      <div className="col-4">
        <div>
          {intl.formatMessage({
            id: DRIVES_HEADER_PAUSE,
          })}
          :
        </div>
        <div style={{ display: "contents" }}>
          <InputNumber
            value={pause}
            onChange={(event) => {
              changeValue(index, "pauseMin", event.value);
            }}
            mode="decimal"
            inputStyle={{
              outline: pause !== null ? "none" : "3px solid red",
            }}
          />
          <Button
            icon="pi pi-minus"
            aria-label="remove"
            style={{
              marginLeft: "5%",
            }}
            onClick={() => deleteRow(index)}
            className="p-button-rounded p-button-danger"
          />
          <Button
            icon="pi pi-plus"
            aria-label="add"
            style={{
              marginLeft: "2%",
              visibility: showButton ? "visible" : "hidden",
            }}
            onClick={addRow}
            className="p-button-rounded p-button-primary"
          />
        </div>
      </div>
    </>
  );
};

export default AdditionalTimeRow;
