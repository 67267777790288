/**
 * This component generates a dialog that enables the user to create a new address entry.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
// PrimeReact components
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { ToggleButton } from "primereact/togglebutton";
import { Checkbox } from "primereact/checkbox";
// Tippy tooltip
import "tippy.js/dist/tippy.css";
// Custom components
import {
  TranslatedCB,
  CustomerSelector,
  AddressSelector,
  FloatingTextInput,
  FloatingNumberInput,
  SplitDateTimeInput,
} from "components/common";
// Helper functions
import { dateToQueryString, valiDate } from "common/Helpers";
// Localization
import { injectIntl } from "react-intl";
// Static values
import { MESSAGE_KEYS } from "assets/staticData/enums";
import { TRANSPORT_TYPES, BILL_STATUS } from "assets/staticData/combodata";
// Styling
import "./Style.scss";
import { InputMask } from "primereact/inputmask";

const INITIAL_STATE = {
  inputKeyWords: "",
  inputTransportType: TRANSPORT_TYPES[0],
  inputBillStatus: [],
  inputBillingCustomer: null,
  inputBillingAddress: null,
  inputPriceMin: null,
  inputPriceMax: null,
  inputIncludeClinics: true,
  inputIncludeInactive: false,
  inputFromDate: undefined,
  inputToDate: undefined,
  inputIncludeUnpaid: false,
  inputIsPeppol: false,

  inputYear: null,
  inputBillNumber: "",
};

class BillFilterLayout extends React.PureComponent {
  static whyDidYouRender = true;
  state = {
    ...INITIAL_STATE,
  };

  /** Reset state variables. */
  resetState = () => {
    this.setState(
      {
        ...INITIAL_STATE,
      },
      this.callSearch(true)
    );
  };
  /** Call search function if enter key is pressed. */
  handleKeyDown = (event) => {
    if (event.key === "Enter" && !this.props.isPending) {
      this.callSearch();
    }
  };

  handleKeywordUpdate = (newKeyword) => {
    const { inputKeyWords, inputFromDate } = this.state;
    let newState = { inputKeyWords: newKeyword };
    if (!inputKeyWords && !inputFromDate) {
      const initStartDate = new Date();
      initStartDate.setFullYear(initStartDate.getFullYear() - 2);
      newState.inputFromDate = initStartDate;
    }
    this.setState({ ...newState });
  };

  callSearch = (isReset = false) => {
    // If reset is false, fetch INITIAL_DATA. Need to do this due to asynchronous stuff.
    const {
      inputKeyWords,
      inputTransportType,
      inputBillStatus,
      inputBillingCustomer,
      inputBillingAddress,
      inputPriceMin,
      inputPriceMax,
      inputIncludeClinics,
      inputIncludeInactive,
      inputFromDate,
      inputToDate,
      inputIncludeUnpaid,
      inputIsPeppol,

      inputYear,
      inputBillNumber,
    } = isReset ? INITIAL_STATE : this.state;
    let inputFilter = {
      keyword: inputKeyWords ? inputKeyWords : "",
      vehicleTypes:
        inputTransportType && inputTransportType.transportTypeId
          ? inputTransportType.transportTypeId
          : null,
      states: inputBillStatus
        ? inputBillStatus.map((status) => status.billStatusId)
        : null,
      transactionCustomer:
        inputBillingCustomer && inputBillingCustomer.personId
          ? inputBillingCustomer.personId
          : null,
      transactionAddress:
        inputBillingAddress && inputBillingAddress.addressId
          ? inputBillingAddress.addressId
          : null,
      min: inputPriceMin ? inputPriceMin : null,
      max: inputPriceMax ? inputPriceMax : null,
      excludeAmbulances: !inputIncludeClinics,
      includeInactive: inputIncludeInactive,
      fromDate: inputFromDate ? dateToQueryString(inputFromDate) : null,
      toDate: inputToDate ? dateToQueryString(inputToDate) : null,
      invoiceNumber: inputBillNumber ? inputBillNumber : null,
      invoiceYear:
        inputYear && inputYear.length === 4 ? inputYear.substring(2) : null,
      openPayment: inputIncludeUnpaid,
      peppolParticipantExists: inputIsPeppol ? 1 : 0,
    };
    this.props.handleSearch({
      ...inputFilter,
    });
  };

  statusTemplate = (option) => {
    const { intl } = this.props;
    return (
      <div className="status_select_item">
        <i className={`${option.icon} mr-1`} style={{ color: option.color }} />
        <div>{intl.formatMessage({ id: option.messageKey })}</div>
      </div>
    );
  };

  selectedStatusTemplate = (option) => {
    const { intl } = this.props;
    if (option) {
      return (
        <div style={{ color: option.color }}>
          {intl.formatMessage({ id: option.messageKey })}
        </div>
      );
    }

    return intl.formatMessage({ id: MESSAGE_KEYS.BILLS_FILTER_STATUS });
  };

  render = () => {
    const { isPending, intl } = this.props;
    const {
      inputKeyWords,
      inputTransportType,
      inputBillStatus,
      inputBillingCustomer,
      inputBillingAddress,
      inputPriceMin,
      inputPriceMax,
      inputIncludeClinics,
      inputIncludeInactive,
      inputFromDate,
      inputToDate,
      inputIncludeUnpaid,
      inputIsPeppol,

      inputYear,
      inputBillNumber,
    } = this.state;
    const {
      BILLS_FILTER_KEYWORDS,
      BILLS_FILTER_ADDRESS_LABEL,
      BILLS_FILTER_CUSTOMER_LABEL,
      BILLS_FILTER_AMOUNT_MIN,
      BILLS_FILTER_AMOUNT_MAX,
      BILLS_FILTER_STATUS,
      BILLS_FILTER_EXCLUDE,
      BILLS_FILTER_INCLUDE,
      BILLS_FILTER_INCLUDE_INACTIVE,
      BILLS_FILTER_EXCLUDE_INACTIVE,
      BILLS_FILTER_FROM_DATE,
      BILLS_FILTER_TO_DATE,
      BILLS_FILTER_EXCLUDE_UNPAID,
      BILLS_FILTER_INCLUDE_UNPAID,

      BILLS_FILTER_BILL_NUMBER,
      VEHICLES_YEAR_LABEL,

      DIALOG_SEARCH_BUTTON_LABEL,
      DIALOG_CLEAR_FILTER_LABEL,
    } = MESSAGE_KEYS;

    let buttonClass = isPending ? "pi pi-spin pi-spinner" : "pi pi-search";

    let yearPrefix = (new Date().getFullYear() + "").substring(0, 2);

    return (
      <div>
        <div>
          <div className="bill_filter_row mb-4">
            <FloatingTextInput
              id={BILLS_FILTER_KEYWORDS}
              value={inputKeyWords}
              onChange={(event) => this.handleKeywordUpdate(event.target.value)}
              label={intl.formatMessage({ id: BILLS_FILTER_KEYWORDS })}
              onKeyDown={this.handleKeyDown}
              autoFocus
            />
          </div>
          <div className="grid">
            <div className="col-3">
              <SplitDateTimeInput
                value={inputFromDate}
                onChange={(e) => {
                  this.setState({
                    inputFromDate: valiDate(e) ? new Date(e) : null,
                  });
                }}
                label={intl.formatMessage({ id: BILLS_FILTER_FROM_DATE })}
              />
            </div>

            <div className="col-3">
              <SplitDateTimeInput
                value={inputToDate}
                onChange={(e) => {
                  this.setState({
                    inputToDate: valiDate(e) ? new Date(e) : null,
                  });
                }}
                label={intl.formatMessage({ id: BILLS_FILTER_TO_DATE })}
              />
            </div>
            <ToggleButton
              checked={inputIncludeUnpaid}
              offIcon="pi pi-times"
              offLabel={intl.formatMessage({
                id: BILLS_FILTER_INCLUDE_UNPAID,
              })}
              onIcon={"pi pi-check"}
              onLabel={intl.formatMessage({
                id: BILLS_FILTER_EXCLUDE_UNPAID,
              })}
              onChange={(e) => this.setState({ inputIncludeUnpaid: e.value })}
              className="mb-2 align-self-end col-6"
            />
          </div>
          <div className="grid">
            <div className="col-3">
              <FloatingTextInput
                value={inputBillNumber}
                label={intl.formatMessage({ id: BILLS_FILTER_BILL_NUMBER })}
                onChange={(e) => {
                  this.setState({ inputBillNumber: e.target.value });
                }}
                onKeyDown={this.handleKeyDown}
              />
            </div>

            <span className="p-float-label col-2 align-self-end">
              <InputMask
                id="inp_flt_year"
                mask={`${yearPrefix}99`}
                value={inputYear}
                onChange={(e) => {
                  this.setState({ inputYear: e.target.value });
                }}
                onComplete={(e) => {
                  this.callSearch();
                }}
                style={{ maxWidth: "100px" }}
              />
              <label htmlFor="inp_flt_year">
                {intl.formatMessage({ id: VEHICLES_YEAR_LABEL })}
              </label>
            </span>

            <div className="col-3 align-self-end">
              <TranslatedCB
                value={inputTransportType}
                onChange={(selection) =>
                  this.setState({
                    inputTransportType: selection,
                  })
                }
                options={TRANSPORT_TYPES}
              />
            </div>

            <div className="col-4 align-self-end">
              <MultiSelect
                value={inputBillStatus}
                options={BILL_STATUS}
                onChange={(e) => this.setState({ inputBillStatus: e.value })}
                optionLabel="name"
                placeholder={intl.formatMessage({ id: BILLS_FILTER_STATUS })}
                itemTemplate={this.statusTemplate}
                selectedItemTemplate={this.selectedStatusTemplate}
                style={{ width: "100%" }}
              />
            </div>
          </div>

          <div className="bill_filter_row">
            <FloatingNumberInput
              id={BILLS_FILTER_AMOUNT_MIN}
              value={inputPriceMin}
              onChange={(event) => {
                this.setState({ inputPriceMin: event.value });
              }}
              label={intl.formatMessage({ id: BILLS_FILTER_AMOUNT_MIN })}
              className="mr-2"
            />

            <FloatingNumberInput
              id={BILLS_FILTER_AMOUNT_MAX}
              value={inputPriceMax}
              onChange={(event) => {
                this.setState({ inputPriceMax: event.value });
              }}
              label={intl.formatMessage({ id: BILLS_FILTER_AMOUNT_MAX })}
            />
          </div>
          <div className="bill_filter_row filter_full_width">
            <CustomerSelector
              value={inputBillingCustomer}
              onChange={(selection) =>
                this.setState({ inputBillingCustomer: selection })
              }
              placeholder={intl.formatMessage({
                id: BILLS_FILTER_CUSTOMER_LABEL,
              })}
              className="mr-2"
            />

            <AddressSelector
              value={inputBillingAddress}
              onChange={(selection) =>
                this.setState({ inputBillingAddress: selection })
              }
              placeholder={intl.formatMessage({
                id: BILLS_FILTER_ADDRESS_LABEL,
              })}
            />
          </div>
          <div className="bill_filter_row">
            <ToggleButton
              checked={inputIncludeClinics}
              offIcon="pi pi-times"
              offLabel={intl.formatMessage({ id: BILLS_FILTER_EXCLUDE })}
              onIcon={"pi pi-check"}
              onLabel={intl.formatMessage({ id: BILLS_FILTER_INCLUDE })}
              onChange={(e) => this.setState({ inputIncludeClinics: e.value })}
            />

            <div className="bill_filter_peppol">
              <Checkbox
                inputId={"peppol"}
                onChange={(e) => this.setState({ inputIsPeppol: e.checked })}
                checked={inputIsPeppol}
              ></Checkbox>
              <label htmlFor={"peppol"}>
                <div className="peppol-status-img">
                  <img
                    src="assets/layout/images/peppol-icon-alone.png"
                    alt="peppol icon"
                    className="peppol-icon"
                    title="Peppol"
                  />
                </div>
              </label>
            </div>

            <ToggleButton
              checked={inputIncludeInactive}
              offIcon="pi pi-times"
              offLabel={intl.formatMessage({
                id: BILLS_FILTER_EXCLUDE_INACTIVE,
              })}
              onIcon={"pi pi-check"}
              onLabel={intl.formatMessage({
                id: BILLS_FILTER_INCLUDE_INACTIVE,
              })}
              onChange={(e) => this.setState({ inputIncludeInactive: e.value })}
            />
          </div>
        </div>
        <div className="customer_filter_button_row">
          <Button
            icon={buttonClass}
            label={intl.formatMessage({
              id: DIALOG_SEARCH_BUTTON_LABEL,
            })}
            onClick={() => this.callSearch()}
            disabled={isPending}
          />
          <Button
            label={intl.formatMessage({
              id: DIALOG_CLEAR_FILTER_LABEL,
            })}
            onClick={() => {
              this.resetState();
            }}
            disabled={isPending}
          />
        </div>
      </div>
    );
  };
}

export default injectIntl(BillFilterLayout);
