/**
 * This file contains helper Methods are called througout the program.
 *
 * @version 1.0
 * @author [Ian Husting]
 */
import React from "react";
import axios from "axios";
import { confirmDialog } from "primereact/confirmdialog";
// Tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
// Static values
import {
  MESSAGE_KEYS,
  NETWORK_ERROR_CODES,
  QUERIES,
  EMPTY_FILTER_QUERY,
  LOCALES,
  SHIFT_ORDER,
  SHOUTBOX_STATES,
  PEPPOL_STATES,
} from "assets/staticData/enums";
import { ADMIN_ROLE, BILL_STATUS, TITLES } from "assets/staticData/combodata";
import messages from "data/messages";
// React redux
import store from "data/configPersist";
import { APP_SET_BACKEND_AVAILABILITY, SET_TOKEN_EXPIRED } from "actions/types";
// Logging
import * as log from "loglevel";

let token;

const PEPPOL_DEV_SENDER = {
  name: "TIME4DIGITAL S.À R.L",
  address1: "2C, op der Gare",
  address2: "line 2",
  postalCode: "6850",
  city: "Manternach",
  country: "LU",
  peppolId: "9938:LU33035980",
  reference: "Luxambulance",
  vat: "20212433048",
  rcs: "B254887",
  contact: "Kremer Kai",
  email: "kkremer@time4digital.lu",
  phone: "00352292021",
};

// FIXME: Apply Luxambulance settings
const PEPPOL_PROD_SENDER = {
  name: "TIME4DIGITAL S.À R.L",
  address1: "2C, op der Gare",
  address2: "line 2",
  postalCode: "6850",
  city: "Manternach",
  country: "LU",
  peppolId: "9938:LU33035980",
  reference: "Luxambulance",
  vat: "20212433048",
  rcs: "B254887",
  contact: "Kremer Kai",
  email: "kkremer@time4digital.lu",
  phone: "00352292021",
};

/**
 * General error handling for Axios requests. Returns the parent promise's reject with the respective error message.
 *
 * @param {Object} error - The received error message.
 * @param {Object|Undefined} error.response - The server response attached to the error message. (If available)
 * @param {String} error.response.status - The status of the sent Axios request. (If available)
 */
const handleConnectionError = (error, isExternalCall = false) => {
  if (isExternalCall === true) {
    return error;
  }
  let logger = initLogger("handle_connection_error");
  let errorMessage;

  try {
    if (error.response) {
      // Server responded with error code.
      logger.warn(error.response);
      if (error.response.status === NETWORK_ERROR_CODES.UNAUTHORIZED_ERROR) {
        store.dispatch({ type: SET_TOKEN_EXPIRED });
        errorMessage = NETWORK_ERROR_CODES.UNAUTHORIZED_ERROR;
      } else {
        errorMessage = error.response.status;
      }
    } else if (error.request) {
      // No server response.
      logger.warn("No server response", error);
      /*let backendAvailable = store.getState().application.backendAvailable;
      if (backendAvailable) {
        store.dispatch({ type: APP_SET_BACKEND_AVAILABILITY, payload: false });
      }*/
      errorMessage = NETWORK_ERROR_CODES.NO_SERVER_ERROR;
    } else {
      // Unexpected exception.
      errorMessage = NETWORK_ERROR_CODES.EXCEPTION_ERROR;
    }
    return errorMessage;
  } catch (errorException) {
    logger.warn("Exception on handle connection error", error);
    return errorException;
  }
};

/**
 * Function to convert a bill object into a Peppol object
 *
 * @param {Object} bill The bill object
 *
 * @return {Object} Returns a Peppol object.
 * @public
 */
export const convertBillToPeppolInvoice = (bill, shouldResend) => {
  if (bill === null) {
    throw new Error("Given argument is null.");
  } else if (bill?.transactionDetails === null) {
    throw new Error("Cannot send an invoice without positions.");
  }
  const invoiceLines = [];
  const vat = 0;
  const vatCode = "Z";
  const sumTax = 0;

  let total = 0;

  bill?.transactionDetails?.forEach((transaction) => {
    total += transaction?.totalPrice;

    invoiceLines.push({
      position: transaction?.position,
      name: transaction?.productName,
      description: transaction?.productName,
      quantity: transaction?.amount,
      reference: transaction?.transactionDetailsId,
      unitPriceNoTax: transaction?.price,
      totalNoTax: transaction?.totalPrice,
      totalWithTax: transaction?.totalPrice,
      unitCode: "H87",
      discountAmount: 0,
      discountPercentage: 0,
      sumTax: 0,
      vat: vat,
      vatCode: vatCode,
    });
  });

  let peppolObject = {
    date: bill?.orderDate,
    dueDateText: "Not given",
    invoiceReference: bill?.invoiceNumber,
    customerProjectReference: "ADDRID-" + bill?.invoiceAddress?.addressId,
    senderProjectReference: "Luxambulance",
    sumTaxNormal: sumTax,
    subtotalNoTax: total,
    totalWithTax: total,
    linesSumAmount: total,
    sumTaxIntermediate: 0,
    baseIntermediate: 0,
    baseNormal: 0,
    sumTaxReduced: 0,
    baseReduced: 0,
    sumTaxSuperReduced: 0,
    baseSuperReduced: 0,
    totalTaxCombined: 0,
    sender: getPeppolSender(),
    customer: {
      name: bill?.invoiceAddress?.name,
      address1: bill?.invoiceAddress?.line1,
      address2: bill?.invoiceAddress?.line2,
      postalCode: bill?.invoiceAddress?.zipCode,
      city: bill?.invoiceAddress?.countryProvince,
      country: bill?.invoiceAddress?.country ?? "LU",
      peppolId: bill?.invoiceAddress?.peppolParticipantId,
      reference: "ADDRID-" + bill?.invoiceAddress?.addressId,
    },
    invoiceLines: invoiceLines,
    taxTotal: {
      taxTotalAmount: sumTax,
      taxSubtotals: [
        {
          code: vatCode,
          percentage: vat,
          baseAmount: total,
          taxAmount: sumTax,
        },
      ],
    },
    allowances: [],
    charges: [],
    test: getCurrentEnv() === "prod" ? 0 : 1,
  };

  //
  if (shouldResend === true) {
    peppolObject.invoiceTypeCode = 381;
  }

  return peppolObject;
};

/**
 * Function to get the Peppol sender based on ENV.
 *
 * @return {Object} Returns the Peppol sender.
 * @public
 */
const getPeppolSender = () => {
  return getCurrentEnv() === "prod" ? PEPPOL_PROD_SENDER : PEPPOL_DEV_SENDER;
};

/**
 * Function to handle the Peppol request
 *
 * @return {String} Returns a string containing 'prod' or 'dev' based on ENV.
 * @public
 */
const getCurrentEnv = () => {
  switch (process.env.REACT_APP_SETTING) {
    case "prod":
    case "start-prod":
      return "prod";
    case "demo":
    case "start-demo":
      return "dev";
    case "kai":
    case "start-local-kai":
      return "dev";
    default:
      return "dev";
  }
};

/**
 * Function to send an invoice to Peppol using bill object.
 *
 * @param {Object} bill The bill object
 *
 * @return {Promise} Resolves with the response or rejects with an error message.
 * @public
 */
export const sendInvoiceToPeppol = async (bill) => {
  let newStatus = null;
  let payloadLog = null;

  const shouldResend = hasPeppolTransactionSuccessLog(bill);
  try {
    const peppolBillEntity = convertBillToPeppolInvoice(bill, shouldResend);
    token = await getPeppolToken();

    // Validate the Peppol request
    await handlePeppolRequest(peppolBillEntity, true, shouldResend);

    const response = await handlePeppolRequest(
      peppolBillEntity,
      false,
      shouldResend
    );

    if (response?.message !== "Success") {
      throw new Error(response);
    } else {
      newStatus = PEPPOL_STATES.SUCCESS;
      payloadLog = logPeppolMessage(bill?.transactionId, newStatus);
      addLocalPeppolLog(bill, payloadLog);
    }
    return response;
  } catch (ex) {
    newStatus = PEPPOL_STATES.FAILED;
    const errMessage = ex.message || "Unknown error occurred";
    payloadLog = logPeppolMessage(bill?.transactionId, newStatus, errMessage);
    addLocalPeppolLog(bill, payloadLog);
    throw new Error("Request error:" + ex?.message);
  }
};

export const addLocalPeppolLog = (bill, log) => {
  if (bill == null || log == null) return;
  bill.transactionPeppolLogs = [...bill.transactionPeppolLogs, log];
};
/**
 * Function to get a token from Peppol.
 *
 * @return {String} Returns the token as string
 * @public
 */
const getPeppolToken = async () => {
  try {
    return sendQuery(
      QUERIES.PEPPOL_LOGIN,
      "post",
      getPeppolCredentials(),
      "json",
      true
    ).then(
      (response) => {
        const token = response?.accessToken;
        if (token) {
          return token;
        } else {
          throw new Error(response?.error);
        }
      },

      (error) => {
        throw new Error(error?.message);
      }
    );
  } catch (fetchException) {
    throw new Error(fetchException?.message);
  }
};

export const logPeppolMessage = (billId, peppolState, message = null) => {
  try {
    if (billId == null) {
      console.warn("Could not log peppol state, billId is null");
      return;
    }

    const payload = {
      transactionId: billId,
      peppolSendDate: new Date(),
      peppolState: peppolState,
      peppolRequestMessage: message,
    };

    sendQuery(QUERIES.ADD_PEPPOL_TRANSACTION_LOG, "POST", payload);
    return payload;
  } catch (ex) {
    console.error("Could not log peppol message due to: " + ex?.message);
  }
};

export const hasPeppolTransactionSuccessLog = (bill) => {
  return bill?.transactionPeppolLogs.some(
    (item) => item?.peppolState === PEPPOL_STATES.SUCCESS
  );
};
/**
 * Function to get the Peppol credentials based on the current environment
 *
 * @return {Object} Returns an object containing username and password
 * @public
 */
const getPeppolCredentials = () => {
  return {
    username:
      getCurrentEnv() === "prod"
        ? process.env.REACT_APP_PEPPOL_USERNAME_PROD
        : process.env.REACT_APP_PEPPOL_USERNAME_DEV,
    password:
      getCurrentEnv() === "prod"
        ? process.env.REACT_APP_PEPPOL_PASSWORD_PROD
        : process.env.REACT_APP_PEPPOL_PASSWORD_DEV,
  };
};

/**
 * Function to handle the Peppol request
 *
 * @param {Object} peppolBillEntity The Peppol bill DTO
 * @param {Boolean} isValidation  Determines if this request should be a Beinvoice validation request or a real send request
 *
 * @return {Object} Returns the response of the Peppol request
 * @public
 */
const handlePeppolRequest = async (
  peppolBillEntity,
  isValidation,
  shouldResend
) => {
  const url = `${QUERIES.SET_PEPPOL_DOCUMENT}/${
    isValidation ? "validate" : "send"
  }${shouldResend === true ? "?resend=1" : ""}`;

  return sendQuery(url, "post", peppolBillEntity, "json", true)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err?.request?.response;
    });
};

/**
 * General function to send requests to the server with appropriate error handling.
 *
 * @param {String} url The URL to the backend's API point.
 * @param {String} method Defines the request's sending method, eiter POST or GET.
 * @param {Object} data Contains data that's to e sent in the request's header.
 * @param {String} responseType Defaults to JSON. Sets the expected response type of the request response.
 *
 * @return {Promise<ServerResponse>} Returns a promise with the requested data from the server on success, an error message else.
 * @public
 */
export const sendQuery = (
  url,
  method = "post",
  data,
  responseType = "json",
  isExternalCall = false
) => {
  return new Promise((resolve, reject) => {
    let logger = initLogger("send_query");
    try {
      let currentUser = store.getState().authentication.currentUser;
      let backendAvailable = store.getState().application.backendAvailable;
      let headers;
      let backendUrl;
      let backendAppendix;

      switch (process.env.REACT_APP_SETTING) {
        case "prod":
        case "start-prod":
          backendUrl = process.env.REACT_APP_DB_HOST_PROD;
          backendAppendix = process.env.REACT_APP_DB_HOST_QUERY_PROD;
          break;
        case "demo":
        case "start-demo":
          backendUrl = process.env.REACT_APP_DB_HOST_DEMO;
          backendAppendix = process.env.REACT_APP_DB_HOST_QUERY_DEMO;
          break;
        case "kai":
        case "start-local-kai":
          backendUrl = process.env.REACT_APP_DB_HOST_KAI;
          backendAppendix = process.env.REACT_APP_DB_HOST_QUERY_KAI;
          break;
        default:
          backendUrl = process.env.REACT_APP_DB_HOST_DEV;
          backendAppendix = process.env.REACT_APP_DB_HOST_QUERY_DEV;
      }

      if (url === QUERIES.AUTHENTICATION || isExternalCall === true) {
        headers = {
          "Access-Control-Allow-Origin": "*",
          "Content-Security-Policy": `script-src 'self' '${
            isExternalCall === false ? backendUrl : "https://beinvoice.lu"
          }'`,
          Authorization: "Bearer " + token,
        };
      } else {
        const { type, token } = currentUser;
        let authToken = `${type} ${token}`;

        if (data && method.toLowerCase() === "get") {
          headers = {
            "Access-Control-Allow-Origin": "*",
            consumes: "application/json",
            produces: "application/json",
            "Content-Security-Policy": `script-src 'self' '${backendUrl}'`,
            Authorization: authToken ? authToken : "",
            "Content-Type": "application/json",
          };
        } else {
          headers = {
            "Access-Control-Allow-Origin": "*",
            "Content-Security-Policy": `script-src 'self' '${backendUrl}'`,
            Authorization: authToken ? authToken : "",
          };
        }
      }

      const urlAppended =
        isExternalCall === false
          ? `${backendUrl}${backendAppendix}${url}`
          : url;

      let axiosConfig = {
        headers,
        responseType,
        url: urlAppended,
        method,
        //timeout: 10000, TODO Uncomment for session timeouts.
      };

      if (data) {
        axiosConfig.data = data;
      }

      logger.info(
        "REQUEST",
        {
          url: urlAppended,
          headers,
          data,
        },
        axiosConfig
      );

      return axios(axiosConfig)
        .then(
          (result) => {
            logger.info(result);
            if (!backendAvailable) {
              store.dispatch({
                type: APP_SET_BACKEND_AVAILABILITY,
                payload: true,
              });
            }
            if (result && result.hasOwnProperty("data")) {
              resolve(result.data);
            } else {
              reject(result.data.errors[0].message);
            }
          },
          (error) => {
            logger.error("Axios rejected.", error);
            reject(handleConnectionError(error, isExternalCall));
          }
        )
        .catch((error) => {
          logger.error("Exception on axios request.");
          reject(handleConnectionError(error, isExternalCall));
        });
    } catch (graphqlException) {
      logger.error("General exception", graphqlException);
      reject(handleConnectionError(graphqlException.message, isExternalCall));
    }
  });
};

/**
 * @typedef {Object} CalendarConfig
 * @property {Number} firstDayOfWeek - Defines what day will be the fist in the displayed calendar - set to 1 (Monday)
 * @property {Array<String>} dayNames - The list of translated full day names.
 * @property {Array<String>} dayNamesShort - The list of translated short day names.
 * @property {Array<String>} dayNamesMin - The list of translated minimal day names - same content as dayNamesShort.
 * @property {Array<String>} monthNames - The list of translated full month names.
 * @property {Array<String>} monthNamesShort - The list of translated short month names.
 */

/**
 * Returns the translated calendar labels for PrimeReact's calendar input.
 *
 * @param {String} localeKey A string key that determines the language of the config object. Defaults to "DE".
 * @return {CalendarConfig} Returns the translated calendar configuration
 * @public
 */
export const generateCalendarLocale = (localeKey = LOCALES.GERMAN.key) => {
  const {
    MONDAY_LONG,
    TUESDAY_LONG,
    WEDNESDAY_LONG,
    THURSDAY_LONG,
    FRIDAY_LONG,
    SATURDAY_LONG,
    SUNDAY_LONG,

    MONDAY_SHORT,
    TUESDAY_SHORT,
    WEDNESDAY_SHORT,
    THURSDAY_SHORT,
    FRIDAY_SHORT,
    SATURDAY_SHORT,
    SUNDAY_SHORT,

    JANUARY_LONG,
    FEBRUARY_LONG,
    MARCH_LONG,
    APRIL_LONG,
    MAY_LONG,
    JUNE_LONG,
    JULY_LONG,
    AUGUST_LONG,
    SEPTEMBER_LONG,
    OCTOBER_LONG,
    NOVEMBER_LONG,
    DECEMBER_LONG,

    JANUARY_SHORT,
    FEBRUARY_SHORT,
    MARCH_SHORT,
    APRIL_SHORT,
    MAY_SHORT,
    JUNE_SHORT,
    JULY_SHORT,
    AUGUST_SHORT,
    SEPTEMBER_SHORT,
    OCTOBER_SHORT,
    NOVEMBER_SHORT,
    DECEMBER_SHORT,

    DIALOG_CLEAR_FILTER_LABEL,

    TODAY,

    STARTS_WITH,
    CONTAINS,
    NOT_CONTAINS,
    ENDS_WITH,
    EQUALS,
    NOT_EQUALS,
    NO_FILTER,
  } = MESSAGE_KEYS;

  let dayNamesShort = [
    messages[localeKey][SUNDAY_SHORT],
    messages[localeKey][MONDAY_SHORT],
    messages[localeKey][TUESDAY_SHORT],
    messages[localeKey][WEDNESDAY_SHORT],
    messages[localeKey][THURSDAY_SHORT],
    messages[localeKey][FRIDAY_SHORT],
    messages[localeKey][SATURDAY_SHORT],
  ];

  return {
    startsWith: messages[localeKey][STARTS_WITH],
    contains: messages[localeKey][CONTAINS],
    notContains: messages[localeKey][NOT_CONTAINS],
    endsWith: messages[localeKey][ENDS_WITH],
    equals: messages[localeKey][EQUALS],
    notEquals: messages[localeKey][NOT_EQUALS],
    noFilter: messages[localeKey][NO_FILTER],
    firstDayOfWeek: 1,
    dayNames: [
      messages[localeKey][SUNDAY_LONG],
      messages[localeKey][MONDAY_LONG],
      messages[localeKey][TUESDAY_LONG],
      messages[localeKey][WEDNESDAY_LONG],
      messages[localeKey][THURSDAY_LONG],
      messages[localeKey][FRIDAY_LONG],
      messages[localeKey][SATURDAY_LONG],
    ],
    dayNamesShort,
    dayNamesMin: dayNamesShort,
    monthNames: [
      messages[localeKey][JANUARY_LONG],
      messages[localeKey][FEBRUARY_LONG],
      messages[localeKey][MARCH_LONG],
      messages[localeKey][APRIL_LONG],
      messages[localeKey][MAY_LONG],
      messages[localeKey][JUNE_LONG],
      messages[localeKey][JULY_LONG],
      messages[localeKey][AUGUST_LONG],
      messages[localeKey][SEPTEMBER_LONG],
      messages[localeKey][OCTOBER_LONG],
      messages[localeKey][NOVEMBER_LONG],
      messages[localeKey][DECEMBER_LONG],
    ],
    monthNamesShort: [
      messages[localeKey][JANUARY_SHORT],
      messages[localeKey][FEBRUARY_SHORT],
      messages[localeKey][MARCH_SHORT],
      messages[localeKey][APRIL_SHORT],
      messages[localeKey][MAY_SHORT],
      messages[localeKey][JUNE_SHORT],
      messages[localeKey][JULY_SHORT],
      messages[localeKey][AUGUST_SHORT],
      messages[localeKey][SEPTEMBER_SHORT],
      messages[localeKey][OCTOBER_SHORT],
      messages[localeKey][NOVEMBER_SHORT],
      messages[localeKey][DECEMBER_SHORT],
    ],
    today: messages[localeKey][TODAY],
    clear: messages[localeKey][DIALOG_CLEAR_FILTER_LABEL],
  };
};

/**
 * Converts an UTC date to a regular date without timezone offset.
 *
 * @param {Date} utcDate The UTC date to convert
 * @returns {Date} The converted date.
 * @public
 */
export const utcToDate = (utcDate) => {
  try {
    let convDate = new Date(utcDate);
    convDate.setTime(convDate.getTime() + convDate.getTimezoneOffset() * 60000);
    return convDate;
  } catch (conversationException) {
    initLogger("utc_to_date").error(conversationException);
    return new Date();
  }
};

/**
 * Checks if the parameter is a string or a date value.
 * If it is a string, it returns the string as date object with timezone offset.
 * If it is a date, it'll return the date.
 *
 * @param {String|Date} date
 * @returns {Date} The converted date.
 */
export const stringToOffsetDate = (date) => {
  try {
    if (date) {
      let tmpDate = typeof date === "string" ? new Date(date) : date;
      return new Date(tmpDate.getTime() + tmpDate.getTimezoneOffset() * 60000);
    } else {
      return null;
    }
  } catch (mapException) {
    initLogger("string_to_offset_date").error(mapException);
    return null;
  }
};

/**
 * Generates a standard JSX element to display a validation error message.
 * The message key itself will serve as element key.
 *
 * @param {String} messageKey The message key of the validation error.
 * @param {Object} intl Reference to the classes translation function.
 * @returns {JSX.Element} The generated validation error.
 * @public
 */
export const generateValidationError = (messageKey, intl) => {
  return (
    <span key={messageKey}>
      {intl.formatMessage({ id: messageKey })}
      <br />
    </span>
  );
};

/**
 * Generates a date item for the next day of the parameter date.
 * Maps both dates to a string date and returns them in an array on success.
 * Returns today and tomorrow on error.
 *
 * @param {Date} date The respective date.
 * @returns {Array} Start date & end date as strings.
 */
export const dateToParameter = (date) => {
  try {
    let result = [];
    result.push(
      `${date.getFullYear()}-${
        parseInt(date.getMonth()) + 1 <= 9
          ? "0" + (parseInt(date.getMonth()) + 1)
          : date.getMonth() + 1
      }-${date.getDate() <= 9 ? "0" + date.getDate() : date.getDate()}`
    );
    let endDate = new Date(date);
    endDate.setDate(endDate.getDate() + 1);
    result.push(
      `${endDate.getFullYear()}-${
        parseInt(endDate.getMonth()) + 1 <= 9
          ? "0" + (parseInt(endDate.getMonth()) + 1)
          : endDate.getMonth() + 1
      }-${endDate.getDate() <= 9 ? "0" + endDate.getDate() : endDate.getDate()}`
    );
    return result;
  } catch (mapException) {
    initLogger("date_to_parameter").error(mapException);
    return this.dateToParameter(new Date());
  }
};

/**
 * Generate query parameter string based on the filter input.
 * @param {Object} filter An object containing the filter input data, where the key is the query parameter and its value the input value.
 * @param {String} orderBy The column to order the results by. Leave empty to skip ordering. Defaults to null.
 * @returns {String} Generated query string on success, default query string with isEmpty = true & sort by persond id desc.
 */
export const generateQueryParameters = (filter, orderBy = null) => {
  try {
    let isEmpty = true;
    let queryString = "&";
    Object.keys(filter).forEach((filterName) => {
      // Check if parameter is empty.
      let filterValue = filter[filterName];
      if (isEmpty) {
        isEmpty =
          filterValue === "" ||
          filterValue === null ||
          filterValue === undefined ||
          (Array.isArray(filterValue) && filterValue.length === 0) ||
          typeof filterValue === "boolean"; // TODO Default value check for boolean required.
      }
      queryString += `${filterName}=${
        filterValue !== null ? filterValue : ""
      }&`;
    });
    queryString += `queryIsEmtpy=${isEmpty}${
      orderBy ? `&sort=${orderBy},desc` : ""
    }`;
    return queryString;
  } catch (generateException) {
    initLogger("generate_query_parameters").error(
      "Exception on generateQueryParameters",
      generateException
    );
    return EMPTY_FILTER_QUERY;
  }
};

/**
 * Default table cell template for prices.
 * Displays the received value two decimal digits and the Euro sign if set, displays 0.00€ else.
 *
 * @param {Number} value
 */
export const priceCellTemplate = (value = 0) => {
  return (
    <div>{`${value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
    })} €`}</div>
  );
};

/**
 * Default table cell template displaying first and last name in one cell.
 *
 * @param {String} firstName
 * @param {String} lastName
 */
export const fullNameTemplate = (firstName, lastName) => {
  return <div>{`${firstName ?? ""} ${lastName ?? ""}`}</div>;
};

/**
 * Attempts to transform a date into a formatted string value. (dd.MM.YYYY)
 *
 * @param {Date|String} value
 * @param {Char} separator The char separating the values in the result string. Defaults to .
 * @returns {String}
 */
export const dateToString = (value, separator = ".") => {
  try {
    let tmpDate = new Date(value);
    if (!isNaN(tmpDate.getTime())) {
      let dayValue = addLeadingZero(tmpDate.getDate());
      let monthValue = addLeadingZero(tmpDate.getUTCMonth() + 1);
      return `${dayValue}${separator}${monthValue}${separator}${tmpDate.getFullYear()}`;
    }
  } catch (mapException) {
    initLogger("date_to_string").warn(
      "Exception on dateCellTemplate with",
      value,
      mapException
    );
    return value;
  }
};
/**
 * Attempts to map a date into an absolutely barbaric string accepted by the backend.
 *
 * @param {Date|String} value
 * @param {Boolean} includeTime
 * @param {String} delimiter
 * @returns {String}
 */
export const dateToQueryString = (
  value,
  includeTime = false,
  delimiter = "-"
) => {
  let formattedDate = "";
  try {
    let tmpDate = new Date(value);
    if (!isNaN(tmpDate.getTime())) {
      let dayValue =
        tmpDate.getDate() <= 9 ? `0${tmpDate.getDate()}` : tmpDate.getDate();
      let tmpMonth = tmpDate.getMonth() + 1;
      let monthValue = tmpMonth <= 9 ? `0${tmpMonth}` : tmpMonth;
      formattedDate = `${tmpDate.getFullYear()}${delimiter}${monthValue}${delimiter}${dayValue}`;
      if (includeTime) {
        formattedDate += ` ${dateToTime(tmpDate)}`;
      }
      return formattedDate;
    }
  } catch (mapException) {
    initLogger("date_to_query_string").error(
      "Exception on date to querystring with",
      value,
      mapException
    );
    return value;
  }
};

/**
 * Attempts to map a date into an string accepted by the input mask.
 *
 * @param {Date|String} value
 * @param {Boolean} includeTime
 * @param {String} delimiter
 * @returns {String}
 */
export const dateToMaskString = (
  value,
  includeTime = false,
  delimiter = "-"
) => {
  let formattedDate = "";
  try {
    const dumDate = new Date(value);

    let tmpDate = dumDate.getTime() + dumDate.getTimezoneOffset() * 60000;
    if (!isNaN(tmpDate.getTime())) {
      let dayValue = addLeadingZero(tmpDate.getDate());
      let tmpMonth = tmpDate.getUTCMonth() + 1;
      let monthValue = addLeadingZero(tmpMonth);
      formattedDate = `${dayValue}${delimiter}${monthValue}${delimiter}${tmpDate.getFullYear()}`;
      if (includeTime) {
        formattedDate += ` ${dateToTime(tmpDate, typeof value === "string")}`;
      }
      return formattedDate;
    }
  } catch (mapException) {
    initLogger("date_to_mask_string").warn(
      "Exception on date to querystring with",
      value,
      mapException
    );
    return value;
  }
};
/**
 * Extracts and returns the time of day from the received date on success.
 * Returns an empty string else.
 *
 * @param {Date} date The date value to extract the time from.
 * @param {boolean} withOffset Set to true to include the timezone offset. Defaults to false.
 * @returns {String} The time of the received date, mapped as hh:mm.
 */
export const dateToTime = (date, withOffset = false) => {
  try {
    let dumHours = date.getHours();
    if (withOffset) {
      dumHours += date.getTimezoneOffset() / 60;
    }
    let tmpHours = dumHours <= 9 ? `0${dumHours}` : dumHours;
    let tmpMinutes =
      date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes();
    return `${tmpHours}:${tmpMinutes}`;
  } catch (timeException) {
    initLogger("date-to-time").error(timeException);
    return "";
  }
};

/**
 * Attempts to map a date into an ISO string (yyyy-MM-ddTHH:mm:00.000Z).
 * Custom function required as default dateToISOString reduces day value by 1.
 *
 * @param {Date|String} value
 * @param {Boolean} defaultTime Set to true to set time to 12:00:00, false to use supplied time. Defaults to true.
 * @returns {String} The date mapped to ISO on success, the unformatted date else.
 */
export const dateToISOString = (value, defaultTime = true) => {
  let formattedDate = "";
  try {
    let tmpDate = new Date(value);
    if (!isNaN(tmpDate.getTime())) {
      let dayValue =
        tmpDate.getDate() <= 9 ? `0${tmpDate.getDate()}` : tmpDate.getDate();
      let tmpMonth = tmpDate.getMonth() + 1;
      let monthValue = tmpMonth <= 9 ? `0${tmpMonth}` : tmpMonth;
      let tmpTime = "12:00";
      if (!defaultTime) {
        tmpTime = dateToTime(tmpDate);
      }
      formattedDate = `${tmpDate.getFullYear()}-${monthValue}-${dayValue}T${tmpTime}:00.000Z`;
      return formattedDate;
    }
  } catch (mapException) {
    initLogger("date_to_iso_string").warn(
      "Exception on date to querystring with",
      value,
      mapException
    );
    return value;
  }
};

/**
 * Default table cell template for dates.
 * Converts and returns the received date as YYYY.MM.DD on success, returns the unformatted value else.
 * Returns an empty div if no value was supplied.
 *
 * @param {Date|String} value
 */
export const dateCellTemplate = (value) => {
  try {
    if (!value || value === "") {
      return <div>-</div>;
    } else {
      return <div>{dateToString(value)}</div>;
    }
  } catch (renderException) {
    initLogger("date_cell_template").error(
      "Exception on dateCellTemplate",
      renderException
    );
    return <></>;
  }
};

export const invoiceTemplate = (number, date) => {
  try {
    return <div>{`${number}/${dateToBillNumber(date)}`}</div>;
  } catch (renderException) {
    initLogger("invoice-template").error(renderException);
    return <div>-</div>;
  }
};

export const responsiveTextCellTemplate = (value) => {
  try {
    if (!value || value === "") {
      return <div>-</div>;
    } else {
      return (
        <Tippy content={value} trigger="click">
          <i
            className="pi pi-comment"
            style={{ border: "2px solid", borderRadius: "5px", padding: "5px" }}
          />
        </Tippy>
      );
    }
  } catch (renderException) {
    initLogger("responsive_text_cell_template").warn(
      "Exception on responsiveCellTemplate",
      renderException
    );
    return <></>;
  }
};

/**
 * Initializes a logger for the respective view.
 *
 * @param {String} viewName
 * @return {import("loglevel").Logger}
 */
export const initLogger = (viewName) => {
  if (process.env.NODE_ENV && process.env.NODE_ENV !== "development") {
    log.setLevel(log.levels.SILENT);
  } else {
    log.setLevel(log.levels.TRACE);
  }
  return log.getLogger(viewName);
};

/**
 * Maps a date string to bill number.
 * The bill number uses the final to digits of the date's year.
 *
 * @param {String} billDate
 * @returns {String} The final to digits of the year on success, an empty string else.
 */
export const dateToBillNumber = (billDate) => {
  let billNumber = "";
  try {
    let tmpDate = new Date(billDate);
    if (!isNaN(tmpDate.getTime())) {
      billNumber = tmpDate.getFullYear().toString().slice(-2);
    }
    return billNumber;
  } catch (mapException) {
    initLogger("date_to_bill_number").error("Exception on date to billnumber");
    return "";
  }
};

/**
 * Check if two objects are different, based on the property with the respective key.
 * Returns true if both properties are equal or both objects are null.
 * Returns false if one of the objects is null or if the properties are not equal.
 * Returns false if an exception occurs.
 *
 * @param {Object} firstObject The first comparison object. Defaults to null.
 * @param {Object} secondObject The second comparison object. Defaults to null.
 * @param {String} idKey The key of the comparing property.
 *
 * @returns {Boolean} True if objects are equal, false else.
 */
export const equalObjects = (
  firstObject = null,
  secondObject = null,
  idKey
) => {
  let logger = initLogger("equal_objects");
  let result = false;
  try {
    // Check if either objects are set
    if (firstObject !== null && secondObject !== null) {
      // Check if both object have the required property.
      if (
        firstObject.hasOwnProperty(idKey) &&
        secondObject.hasOwnProperty(idKey)
      ) {
        // Compare properties.
        result = firstObject[idKey] === secondObject[idKey];
      } else {
        // Check if both are empty.
        result =
          Object.keys(firstObject).length === 0 &&
          Object.keys(secondObject).length === 0;
      }
    } else {
      // Check if both objects are null/empty/undefined.
      result = firstObject === null && secondObject === null;
    }
    return result;
  } catch (compareException) {
    logger.error(
      "Exception on equal objects check",
      compareException,
      firstObject,
      secondObject
    );
    return false;
  }
};

/**
 * Compares dates without time.
 * Returns true if dates are equal, false else.
 * Returns false on exception
 *
 * @param {Date|String} firstDate The first comparison date.
 * @param {Date|String} secondDate The second comparison date.
 *
 * @returns {Boolean} True if dates are equal, false else.
 */
export const equalDates = (firstDate = null, secondDate = null) => {
  let result = false;
  try {
    let firstCmpDate = null;
    if (firstDate) {
      if (typeof firstDate === "string") {
        firstCmpDate = new Date(firstCmpDate);
      } else {
        firstCmpDate = firstDate;
      }
    }
    let secondCmpDate = null;
    if (secondDate) {
      if (typeof secondDate === "string") {
        secondCmpDate = new Date(secondCmpDate);
      } else {
        secondCmpDate = secondDate;
      }
    }
    if (firstCmpDate && secondCmpDate) {
      firstCmpDate.setHours(0, 0, 0, 0);
      secondCmpDate.setHours(0, 0, 0, 0);
      result = firstCmpDate.getTime() === secondCmpDate.getTime();
    } else {
      result = firstCmpDate === secondCmpDate;
    }
    return result;
  } catch (compareException) {
    initLogger("equal_dates").warn(
      "Exception on equal dates",
      compareException
    );
    return false;
  }
};
/**
 * Checks if the received array of user roles contains the admin role.
 * Returns true if found, false else.
 *
 * @param {Array} userRoles
 * @returns {Boolean} True if admin role is found, false else.
 */
export const isAdmin = (userRoles = []) => {
  try {
    if (userRoles && userRoles.length > 0) {
      return (
        userRoles.findIndex((role) => {
          return role.role_id === ADMIN_ROLE.role_id;
        }) >= 0
      );
    }
  } catch (checkException) {
    return false;
  }
};

/**
 * Changes a number into a price-formatted string. (#,## €).
 * Returns the string "0.00 €" if no number is supplied.
 *
 * @param {Number} value
 * @returns {String}
 */
export const numberToPriceString = (value) => {
  let val = value ? value : 0;
  return `${val.toLocaleString(undefined, { minimumFractionDigits: 2 })} €`;
};

/**
 * Checks if an input value is different from it's default value.
 * Applies special checks depending on inputValue's type if the default value is null or undefined.
 * This check is required to ignore change if the default value is not set and the respective input was checked then unchecked again.
 *
 * @param {Boolean} defaultValue The default value received by the backend.
 * @param {Boolean} inputValue  The value entered in the front end.
 * @returns {Boolean} True if the input value differs from the default value, false else.
 */
export const hasValueChanged = (defaultValue, inputValue) => {
  let isChanged;
  if (defaultValue === inputValue) {
    isChanged = false;
  } else if (defaultValue === null || defaultValue === undefined) {
    switch (typeof inputValue) {
      case "boolean":
        isChanged = inputValue === true;
        break;
      case "string":
        isChanged = inputValue !== "";
        break;
      case "number":
        isChanged = inputValue !== 0 || inputValue !== 0.0;
        break;
      default:
        isChanged =
          inputValue !== null || inputValue !== undefined || inputValue !== "";
    }
  } else {
    isChanged = defaultValue !== inputValue;
  }
  return isChanged;
};

/**
 * Filters the given list by active objects.
 * @param {Array<Object>} objectList The array of object to be filtered. Objects within the array should have an active property.
 * @returns {Array<Object>} The filtered array of objects where active is true on success, the unfiltered array else.
 */
export const filterActive = (objectList) => {
  try {
    return objectList.filter((obj) => {
      return obj.active === true;
    });
  } catch (filterException) {
    initLogger("filter_archive").warn(
      "Exception on filter active",
      filterException,
      objectList
    );
    return objectList;
  }
};
/**
 * Checks if the received status is a late status. (Reminder 1, 2 or DAS).
 * Returs true if it is, false else. Returns false on error.
 *
 * @param {Number} billState The ID of the bill state.
 * @returns {Boolean} True if state corresponds to reminder 1, 2 or DAS, false else.
 */
export const isBillLate = (billState) => {
  const { BILL_STATUS_DAS, BILL_STATUS_REMINDER_1, BILL_STATUS_REMINDER_2 } =
    MESSAGE_KEYS;
  let lateNames = [
    BILL_STATUS_DAS,
    BILL_STATUS_REMINDER_1,
    BILL_STATUS_REMINDER_2,
  ];
  let isLate = false;
  try {
    if (!isNaN(billState)) {
      let lateStatusIds = [];
      BILL_STATUS.forEach((status) => {
        if (lateNames.includes(status.messageKey)) {
          lateStatusIds.push(status.billStatusId);
        }
      });
      isLate = lateStatusIds.includes(billState);
    }
    return isLate;
  } catch (checkException) {
    initLogger("is_bill_late").error(
      "Exception on is bill late",
      checkException,
      billState
    );
    return false;
  }
};

/**
 * Attemps to map address DTO properties to a human readable string.
 *
 * @param {Object} address
 * @param {String} address.zipCode
 * @param {String} address.line1
 * @param {String} address.line2
 * @param {String} address.countryProvince
 */
export const addressToString = (address) => {
  try {
    const { countryProvince, line1, line2, zipCode, keyword } = address;
    if (line1 || zipCode || countryProvince || keyword) {
      return `${line1 ? line1 : ""} ${line2 ? line2 : ""} ${
        zipCode ? zipCode : ""
      } ${countryProvince ? countryProvince : ""}${
        keyword ? ` ${keyword}` : ""
      }`;
    }
  } catch (mapException) {
    initLogger("address_to_string").warn(
      "Exception on address to string",
      mapException,
      address
    );
    return "";
  }
};

/**
 * Generates a confirm prompt prompting the user to confirm page change.
 * Is called if the redux value changePending is true and the user changed pages/selection.
 * Executes the received callback function if the dialog was confirmed, closes the confirmation dialog w/o further action else.
 *
 * @param {Object|Number} value The parameter for the callback.
 * @param {Function} callback The function executed on confirmation.
 * @param {Object} intl Localization object
 * @param {Boolean} isRemove Displays delete warning if true, page change warning else. Defaults to false.
 */
export const changePendingConfirm = (
  value,
  callback,
  intl,
  removeLabel = null
) => {
  const {
    WARNING_UNSAVED_CHANGES,
    DIALOG_CONFIRM_BUTTON_LABEL,
    DIALOG_CANCEL_BUTTON_LABEL,
    DIALOG_WARNING_TITLE,
    WARNING_CONFIRM_REMOVE,
  } = MESSAGE_KEYS;

  let message = removeLabel
    ? intl.formatMessage(
        { id: WARNING_CONFIRM_REMOVE },
        {
          value: removeLabel,
        }
      )
    : intl.formatMessage({ id: WARNING_UNSAVED_CHANGES });
  confirmDialog({
    message: (
      <div className="mt-4 flex align-items-center">
        <i
          className="pi pi-exclamation-triangle mr-2"
          style={{ fontSize: "2em" }}
        />
        {message}
      </div>
    ),
    header: intl.formatMessage({ id: DIALOG_WARNING_TITLE }),
    accept: () => callback(value),
    reject: null,
    acceptLabel: intl.formatMessage({ id: DIALOG_CONFIRM_BUTTON_LABEL }),
    rejectLabel: intl.formatMessage({ id: DIALOG_CANCEL_BUTTON_LABEL }),
  });
};

/**
 * Transforms a number into a string value, adds leading zero for single digit numbers.
 *
 * @param {Integer} value
 */
export const addLeadingZero = (value) => {
  try {
    return `${value < 10 ? "0" : ""}${value}`;
  } catch (mapException) {
    return `${value}`;
  }
};
/**
 *  Checks whether or not a string value is a valid date.
 *
 * @param {String} value
 * @returns {Boolean} True if the value is a date, false else.
 */
export const valiDate = (dateString) => {
  try {
    return dateString && Date.parse(dateString);
  } catch (checkException) {
    initLogger("vali_date").warn(
      "Exception on date check",
      checkException,
      dateString
    );
    return false;
  }
};

/**
 * Checks the language assigned to the current user within the redux store and returns to respective language key.
 * Defaults to "DE".
 *
 * @param {boolean} getId Set to true to return the locale id instead of the key. Defaults to false.
 * @returns {String|number} Either "DE" or "FR" if set, defaults to "DE".
 */
export const getCurrentUserLocale = (getId = false) => {
  let currentLocale;
  const { GERMAN } = LOCALES;
  try {
    let currentUser = store.getState().authentication.currentUser;
    if (currentUser?.languageId) {
      currentLocale = Object.keys(LOCALES).find((localeKey) => {
        return LOCALES[localeKey].languageId === currentUser.languageId;
      });
    }
    if (!currentLocale) {
      return getId ? LOCALES[GERMAN].languageId : GERMAN.key;
    } else {
      return getId
        ? LOCALES[currentLocale].languageId
        : LOCALES[currentLocale].key;
    }
  } catch (localeException) {
    initLogger("get_currentUserLocale").warn(localeException);
    return getId ? LOCALES[GERMAN].languageId : GERMAN.key;
  }
};

/**
 * Generates a random 6 letter string consisting of hexadecimal values to generate a random color.
 *
 * @returns {String} A random hexadecimal color code.
 */
export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

/**
 * Searches an array for an object with the corresponding id.
 * Returns to object if found, null else.
 *
 * @param {Array<Object>} array
 * @param {Number} id
 * @param {String} idLabel
 */
export const findObjectById = (array, id, idLabel) => {
  let result;
  try {
    if (array && id && idLabel) {
      result = array.find((entry) => {
        return entry[idLabel] === id;
      });
    } else {
      result = null;
    }
    return result;
  } catch (searchException) {
    initLogger("find_object_by_id").warn(searchException);
    return null;
  }
};

/**
 * Checks if the currently logged in user has admin rights.
 * Return true if user is set and has admin rights, false else.
 */
export const isCurrentUserAdmin = () => {
  try {
    let currentUser = store.getState().authentication.currentUser;
    if (currentUser?.userRoles) {
      return isAdmin(currentUser.userRoles);
    }
  } catch (checkException) {
    initLogger("is_current_user_admin").error(checkException);
    return false;
  }
};

/**
 * Checks if the currently logged in user has the respective roles.
 * Returns true if at least one role is set, false else.
 * @param {Array<Object>} roles The array of roles that the user will be checked on.
 * @param {Object} user If set, the role check will be performed on this user. If not, the user in the redux store will be checked instead. Defaults to null.
 * @returns {boolean} True if the user has at least one role, false else.
 */
export const hasCurrentUserRole = (roles = [], user = null) => {
  let hasRole = false;
  try {
    let currentUser = user ? user : store.getState().authentication.currentUser;
    if (currentUser?.userRoles) {
      const roleIds = roles.map((role) => role.role_id);
      hasRole = currentUser.userRoles.some((userRole) =>
        roleIds.includes(userRole.role_id)
      );
    }
  } catch (checkException) {
    initLogger("is_current_user_admin").error(checkException);
    hasRole = false;
  }
  return hasRole;
};

/**
 * Checks if the received text's length exceeds the supplied maximum length.
 * Returns to text truncated to the max length if true, returns the unchanged text else.
 *
 * @param {String} text The text that's to be checked.
 * @param {Number} maxLength The maximum accepted text length.
 * @returns {String} The truncated text if the text length is larger than maxLength, the unchanged text else.
 */
export const truncateText = (text, maxLength) => {
  let result;
  try {
    if (text.length > maxLength) {
      result = text.substring(0, maxLength);
    } else {
      result = text;
    }
    return result;
  } catch (truncateException) {
    initLogger("truncate_text").warn(truncateException);
    return text;
  }
};
/**
 * Try/catch block to wrap around a render function.
 * Will execute and return the result of the supplied render function on success.
 * Will return a div with the supplied label else.
 *
 * @param {Function} renderFunction The render function that's to be executed.
 * @param {label} String The text that will be displayed on error.
 * */
export const safeRender = (renderFunction, label) => {
  try {
    return renderFunction();
  } catch (renderException) {
    return <div>{label}</div>;
  }
};

/**
 * Checks if two arrays of objects are equal based on their length and id parameters.
 * Returns true if the arrays have the same length and each object id is in both arrays.
 * Returns false else.
 *
 * @param {Array<Object>} array1 The first array.
 * @param {Array<Object>} array2 The second array.
 * @param {String} idLabel The label of the object parameter that will be used for object comparison.
 * @returns {Boolean} True if arrays are equal and contain the same objects (order irrelevant), false else.
 */
export const equalArrays = (array1 = [], array2 = [], idLabel = "") => {
  let areEqual;
  try {
    if (array1.length !== array2.length) {
      areEqual = false;
    } else {
      let cmpArray1 = [];
      let cmpArray2 = [];
      // Extract item ids.
      array1.forEach((item) => {
        cmpArray1.push(item[idLabel]);
      });
      array2.forEach((item) => {
        cmpArray2.push(item[idLabel]);
      });
      // Check if each id of the first array is in the second array.
      return cmpArray1.every((id) => {
        return cmpArray2.includes(id);
      });
    }
    return areEqual;
  } catch (cmpException) {
    initLogger("equal_arrays").error(cmpException, array1, array2, idLabel);
    return false;
  }
};
/**
 * Generates a string containing the title with the supplied title id and the entered name.
 *
 *
 * @param {String} name
 * @param {Number} titleId
 * @param {Object} intl
 * @returns
 */
export const generateName = (name, titleId, intl) => {
  try {
    if (name && name !== " ") {
      let title = null;
      if (titleId !== null && titleId !== undefined) {
        title = TITLES.find((entry) => {
          return entry.titleId === titleId;
        });
      }
      return `${title?.titleId ? title.shn + " " : ""}${name}`;
    } else {
      return name && name !== " "
        ? name
        : intl.formatMessage({
            id: MESSAGE_KEYS.APPOINTMENTS_NO_CUSTOMER_NOTICE,
          });
    }
  } catch (nameGenerationException) {
    initLogger("generate-name").error(nameGenerationException);
    return name;
  }
};

export const renderFullAddress = (address, firstLineAppendix = null) => {
  const delimiter = "#";
  try {
    if (address && address.length > 0) {
      let addressDisplay = [];
      let displayAppendix = firstLineAppendix ? 0 : -1;
      address.split(delimiter).forEach((line) => {
        addressDisplay.push(
          <div
            className="flex"
            key={`${new Date().getTime()}_${Math.random() * 1000}`}
          >
            {line} {displayAppendix === 0 ? firstLineAppendix : null}
          </div>
        );
        if (displayAppendix === 0) {
          displayAppendix = -1;
        }
      });
      return addressDisplay;
    } else {
      return null;
    }
  } catch (renderException) {
    initLogger("render-full-address").error(renderException);
    return null;
  }
};

export const renderBoolean = (value) => {
  return (
    <span>
      {value === true ? (
        <i className="pi pi-check ml-1" style={{ fontSize: "12px" }} />
      ) : (
        <i className="pi pi-times ml-1" style={{ fontSize: "12px" }} />
      )}
    </span>
  );
};

/**
 * The drivers received by an existing appointment are different objects than the simple combobox data object. Find & set the corresponding drivers from the combobox data.
 *
 * @param {Object} driver
 * @param {Array<Object>} drivers
 */
export const mapDriversToComboData = (driver, drivers) => {
  let selectedDriver;
  if (driver) {
    selectedDriver =
      Object.keys(driver).length === 2
        ? driver
        : drivers.find((searchDriver) => {
            return searchDriver.personId === driver.personId;
          });
  } else {
    selectedDriver = null;
  }
  return selectedDriver;
};

/**
 * Clears an array of objects of duplicates.
 *
 * @param {Array<Object>} array The array of objects to be purged.
 * @param {string} property The name of the property by which the objects are compared.
 * @returns The purged array.
 */
export const purgeObjectArray = (array, property) => {
  return array.filter((val, index) => {
    const currentId = val[property];
    return (
      index ===
      array.findIndex((obj) => {
        return obj[property] === currentId;
      })
    );
  });
};

/**
 * Sorts the provided array by the provided property.
 *
 * @param {Array<Object>} array The array of objects to be sorted.
 * @param {string} property The name of the property by which the objects are compared.
 */
export const sortObjectArray = (array, property) => {
  array.sort((a, b) => {
    if (a[property] > b[property]) {
      return 1;
    }
    if (a[property] < b[property]) {
      return -1;
    }
    return 0;
  });
};

const mapResponseToHolidays = (response) => {
  return new Promise((resolve, reject) => {
    try {
      let events = [];
      response.forEach((entry) => {
        const { starttime, bankHolidayName, appointmentId, description } =
          entry;
        events.push({
          id: appointmentId,
          title: bankHolidayName,
          start: new Date(starttime),
          end: new Date(starttime),
          allDay: true,
          display: "background",
          backgroundColor: "#faffd8",
          borderColor: "#faffd8",
          textColor: "black",
          extendedProps: {
            description,
          },
        });
      });
      resolve(events);
    } catch (mapException) {
      reject(mapException);
    }
  });
};

/**
 * Send a request to the backend to receive all defined holidays in the respective time period.
 *
 * @param {Date} startDate Start date of the period
 * @param {Date} endDate End date of the period
 * @returns {Array<Object>} Any received holiday-objects returned by the backend
 */
export const fetchHolidays = (startDate, endDate) => {
  let logger = initLogger("fetch-Holidays");
  return sendQuery(
    `${QUERIES.GET_HOLIDAYS_BY_DATE}?fromDate=${dateToQueryString(
      startDate
    )}&toDate=${dateToQueryString(endDate)}`,
    "GET"
  ).then(
    (response) => {
      return mapResponseToHolidays(response);
    },
    (error) => {
      logger.warn(error);
      return [];
    }
  );
};

/**
 * Maps cars list to a format to fit the cascade selection components.
 *
 * @param {Array<Object>} cars
 */
export const mapCarsToCascadeData = (cars) => {
  let logger = initLogger("map-cars-to-cascade-data");
  let cascadeData;
  try {
    cascadeData = [];
    cars.forEach((car) => {
      let appendGroup;
      // Check if a group for the current transport type exists, fetch that group. Create and add a new group else.
      let groupIndex = cascadeData.findIndex(
        (searchGroup) => car.transportType === searchGroup.name
      );
      if (groupIndex >= 0) {
        appendGroup = cascadeData[groupIndex];
      } else {
        appendGroup = {
          name: car.transportType,
          vehicles: [],
        };
        cascadeData.push(appendGroup);
      }
      // Add only active cars
      if (car.active) {
        appendGroup.vehicles.push(car);
      }
    });
    cascadeData.forEach((group) => {
      sortObjectArray(group.vehicles, "license_plate");
    });
    return cascadeData;
  } catch (mapException) {
    logger.warn("Exception on map cars to cascade", mapException, cars);
    return null;
  }
};

/**
 * Sorts an array of shift objects by the order defined in the SHIFT_ORDER enum.
 *
 * @param {Array<Object>} shifts An unsorted array of shift objects
 * @returns {Array<Object>} The received array of shifts sorted by enum order.
 */
export const setShiftOrder = (shifts = []) => {
  shifts.forEach((shift) => {
    shift.order = SHIFT_ORDER[shift.appointmentTypeId]
      ? SHIFT_ORDER[shift.appointmentTypeId]
      : shifts.length;
  });
  sortObjectArray(shifts, "order");
  return shifts;
};

export const getStatusColor = (status) => {
  let color = {
    bgColor: "lightgray",
    fColor: "black",
  };

  switch (status) {
    case "S1":
      color.bgColor = "rgb(116, 183, 255)";
      color.fColor = "white";
      break;
    case "S2":
      color.bgColor = "rgb(70, 110, 152)";
      color.fColor = "white";
      break;
    case "S3":
      color.bgColor = "rgb(232, 200, 73)";
      color.fColor = "black";
      break;
    case "S4":
      color.bgColor = "rgb(234, 144, 27)";
      color.fColor = "white";
      break;
    case "S5":
      color.bgColor = "rgb(251, 149, 149)";
      color.fColor = "black";
      break;
    case "S6": {
      color.bgColor = "rgb(199, 0, 0)";
      color.fColor = "white";
      break;
    }
    case "S7": {
      color.bgColor = "rgb(254, 103, 44)";
      color.fColor = "white";
      break;
    }
    case "S8":
      color.bgColor = "rgb(192, 74, 28)";
      color.fColor = "white";
      break;
    default:
      break;
  }
  return color;
};

export const getLocalTimeDate = (oldDate = null) => {
  let newDate = null;
  if (!oldDate) {
    newDate = new Date();
  } else {
    newDate = new Date(oldDate);
  }

  const year = newDate.getFullYear();
  const month = String(newDate.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so adding 1 and padding with leading zeros if necessary
  const day = String(newDate.getDate()).padStart(2, "0"); // Padding day with leading zeros if necessary

  return `${year}-${month}-${day}`;
};

export const getStatusNameById = (status, intl) => {
  const {
    DRIVER_STATUS_AVAILABLE_LABEL,
    DRIVER_STATUS_IN_GARAGE_AND_AVAILABLE_LABEL,
    DRIVER_STATUS_ON_THE_ROAD_TO_PATIENT_LABEL,
    DRIVER_STATUS_WITH_PATIENT_LABEL,
    DRIVER_STATUS_CLOSING_TIME_LABEL,
    DRIVER_STATUS_UNLOADING_LABEL,
    DRIVER_STATUS_ON_THE_ROAD_WITH_PATIENT_LABEL,
    DRIVER_STATUS_BREAK_LABEL,
  } = MESSAGE_KEYS;

  switch (status) {
    case "S1":
      return intl.formatMessage({
        id: DRIVER_STATUS_AVAILABLE_LABEL,
      });
    case "S2":
      return intl.formatMessage({
        id: DRIVER_STATUS_IN_GARAGE_AND_AVAILABLE_LABEL,
      });
    case "S3":
      return intl.formatMessage({
        id: DRIVER_STATUS_ON_THE_ROAD_TO_PATIENT_LABEL,
      });
    case "S4":
      return intl.formatMessage({
        id: DRIVER_STATUS_WITH_PATIENT_LABEL,
      });
    case "S5":
      return intl.formatMessage({
        id: DRIVER_STATUS_BREAK_LABEL,
      });
    case "S6":
      return intl.formatMessage({
        id: DRIVER_STATUS_CLOSING_TIME_LABEL,
      });
    case "S7":
      return intl.formatMessage({
        id: DRIVER_STATUS_ON_THE_ROAD_WITH_PATIENT_LABEL,
      });
    case "S8":
      return intl.formatMessage({
        id: DRIVER_STATUS_UNLOADING_LABEL,
      });
    default:
      return "";
  }
};

export const validateTime = (value) => {
  if (value && value.length === 5) {
    const hours = parseInt(value.substring(0, value.indexOf(":")));
    const minutes = parseInt(value.substring(value.indexOf(":") + 1));

    return hours >= 0 && hours <= 24 && minutes >= 0 && minutes <= 59;
  } else {
    return false;
  }
};

/**
 * Maps a date value to a session identifier (d_m_yyyy).
 *
 * @param {Date} date
 * @returns {string}
 */
export const dateToSessionIdentifier = (date = new Date(), reverse = false) => {
  if (reverse) {
    return `${date.getFullYear()}_${date.getMonth() + 1}_${date.getDate()}`;
  } else {
    return `${date.getDate()}_${date.getMonth() + 1}_${date.getFullYear()}`;
  }
};

/**
 * Map session identifier (d_m_yyyy) to date value.
 *
 * @param {string} key Date string in d-m-yyyy format
 * @returns {Date} Date mapped from identifier
 */
const sessionIdentifierToDate = (key) => {
  const [day, month, year] = key.split("_").map(Number);
  if (day && month && year) {
    return new Date(year, month - 1, day);
  } else {
    return new Date();
  }
};

/**
 * Returns an array of date string 3 days before and after the respective date.
 *
 * @param {String} dateString Date string in d-m-yyyy format
 * @returns {Array<String>} Array of date strings 3 days before and after parameter
 */
export const fetchSurrounding = (dateString) => {
  const dateRange = [];
  try {
    const baseDate = sessionIdentifierToDate(dateString);
    const range = 3;
    for (let c = range * -1; c <= range; c++) {
      const validDate = new Date(baseDate);
      validDate.setDate(baseDate.getDate() + c);
      dateRange.push(dateToSessionIdentifier(validDate));
    }
  } catch (fetchException) {
    initLogger("fetch-surrounding").error(
      `Failed fetch surrounding with parameter ${dateString}`,
      fetchException
    );
  }

  return dateRange;
};

/**
 * Checks if the date-parameter is in the range of 3 days before and after baseDate-Parameter.
 *
 * @param {Date} baseDate
 * @param {Date} date
 * @returns {boolean} Returns true is date is within 3 days of baseDate, false else.
 */
export const isWithinRange = (baseDate, date) => {
  try {
    const dateString = dateToSessionIdentifier(date);
    const baseString = dateToSessionIdentifier(baseDate);
    return fetchSurrounding(baseString).includes(dateString);
  } catch (checkException) {
    initLogger("is-within-range").error(
      `Failed is within range ${baseDate} ${date}`,
      checkException
    );
    return true;
  }
};

/**
 * Returns the preset colors of the respective shoutbox state if found.
 * Return white (foreground) and black (background) else.
 *
 * @param {number} type The state id of the respective shoutbox state
 * @returns The fore- and background-color of the respective state if found, black and white else.
 */
export const getColorByMessageType = (type) => {
  let color = {
    bgColor: "white",
    fColor: "black",
  };
  const matchingState = Object.values(SHOUTBOX_STATES).find(
    (state) => state.stateId === type
  );

  if (matchingState) {
    color.bgColor = matchingState.bColor;
    color.fColor = matchingState.fColor;
  }

  return color;
};

/**
 * Checks the received messages object for a translation of the respective language id.
 * Returns the translated texts if found, returns the default title and description else.
 *
 * @param {number} languageId The id of the language for the respective translation
 * @param {Object} message The base message object
 * @param {String} message.title The default title
 * @param {String} message.description The default description
 * @param {Array<Object>} message.translations The array containing objects with translated title and description for the respective language
 * @returns An object containing translation- and language-IDs as well as translated title and description
 */
export const fetchTexts = (languageId, message) => {
  let texts = {
    translationId: null,
    languageId,
    title: message.title,
    description: message.description,
  };
  if (languageId && message.translations) {
    const translation = message.translations.find(
      (trans) => trans.languageId === languageId
    );
    if (translation) {
      texts = translation;
    }
  }
  return texts;
};
